export const MAKEMAKE_LIVE_MONTHS = [
    {
        id: "enero",
        date: "2025-01-01",
        name: {
            default: "Enero",
            translationPath: "meses.enero",
        },
        visible: true,
    },
    {
        id: "febrero",
        date: "2025-02-01",
        name: {
            default: "Febrero",
            translationPath: "meses.febrero",
        },
        visible: true,
    },
    {
        id: "marzo",
        date: "2025-03-01",
        name: {
            default: "Marzo",
            translationPath: "meses.marzo",
        },
        visible: true,
    },
    {
        id: "abril",
        date: "2025-04-01",
        name: {
            default: "Abril",
            translationPath: "meses.abril",
        },
        visible: true,
    },
    {
        id: "mayo",
        date: "2025-05-01",
        name: {
            default: "Mayo",
            translationPath: "meses.mayo",
        },
        visible: true,
    },
    {
        id: "junio",
        date: "2025-06-01",
        name: {
            default: "Junio",
            translationPath: "meses.junio",
        },
        visible: true,
    },
    {
        id: "julio",
        date: "2025-07-01",
        name: {
            default: "Julio",
            translationPath: "meses.julio",
        },
        visible: true,
    },
    {
        id: "agosto",
        date: "2025-08-01",
        name: {
            default: "Agosto",
            translationPath: "meses.agosto",
        },
        visible: true,
    },
    {
        id: "septiembre",
        date: "2025-09-01",
        name: {
            default: "Septiembre",
            translationPath: "meses.septiembre",
        },
        visible: true,
    },
    {
        id: "octubre",
        date: "2025-10-01",
        name: {
            default: "Octubre",
            translationPath: "meses.octubre",
        },
        visible: true,
    },
    {
        id: "noviembre",
        date: "2025-11-01",
        name: {
            default: "Noviembre",
            translationPath: "meses.noviembre",
        },
        visible: true,
    },
    {
        id: "diciembre",
        date: "2025-12-01",
        name: {
            default: "Diciembre",
            translationPath: "meses.diciembre",
        },
        visible: true,
    },
];

export const MAKEMAKE_LIVE_PLAYLISTS = [
    {
        id: "2023",
        name: { default: "Programación 2023", translationPath: "makemake_en_vivo.destacados.tarjeta.programacion_2023" },
        url: "https://www.youtube.com/playlist?list=PLOzDFiyCElH5mo8jNZ9fLh-Zr6VVewrKB",
        img: "/img/club-makemake/programacion/programacion-2023.jpg",
    },
    {
        id: "2022",
        name: { default: "Programación 2022", translationPath: "makemake_en_vivo.destacados.tarjeta.programacion_2022" },
        url: "https://www.youtube.com/playlist?list=PLOzDFiyCElH6qdpXPwpaSZCcwS18rTkHG",
        img: "/img/club-makemake/programacion/programacion-2022.jpg",
    },
    {
        id: "2021",
        name: { default: "Programación 2021", translationPath: "makemake_en_vivo.destacados.tarjeta.programacion_2021" },
        url: "https://www.youtube.com/playlist?list=PLOzDFiyCElH7zPY7KCBiHi6oopJvO0AYx",
        img: "/img/club-makemake/programacion/programacion-2021.jpg",
    },
];