export const READING_PLANS = {
	"enero": {
		"tema": {
			"en": "Death and grief",
			"es": "Muerte y duelo",
		},
		"pregunta": {
			"en": "Can literature give us spaces to process and explore grief and death?",
			"es": "¿Puede la literatura darnos espacios para procesar y explorar el duelo y la muerte?",
		},
		"id_libros": ["MM0041","MM1970", "MM1630"],
	},
    "febrero": {
        "tema": {
            "en": "Remarkable legacies",
            "es": "Vidas notables"
        },
        "pregunta": {
            "en": "Some people have left an important mark on history. These books explore the lives and legacies of remarkable people and invite us to ask ourselves how we can contribute to society.",
            "es": "Algunas personas han dejado una huella importante en la historia. Estas lecturas exploran las vidas y el legado de algunas de ellas y nos invitan a preguntarnos cómo contribuir a la sociedad."
        },
        "id_libros": [
            "MM2556",
            "MM2789",
            "MM1946"
        ]
    },
    "marzo": {
        "tema": {
            "en": "Classics",
            "es": "Clásicos"
        },
        "pregunta": {
            "en": "A classic book is one that, despite the passage of time, remains relevant. These stories offer us visions of things past but also of everything that remains the same.",
            "es": "Un libro clásico es aquel que, a pesar del paso del tiempo, sigue siendo relevante. Estas historias nos ofrecen una visión de otras épocas pero también de lo que permanece igual. "
        },
        "id_libros": [
            "MM0969",
            "MM2116",
            "MM1533"
        ]
    },
    "abril": {
        "tema": {
            "en": "Our Planet",
            "es": "Nuestro Planeta"
        },
        "pregunta": {
            "en": "For centuries, humans have changed, for better and for worse, the world around us. These books invite us to reflect on human intervention on the planet.",
            "es": "Desde hace siglos, los humanos hemos cambiado, para bien y para mal, el mundo a nuestro alrededor. Estos libros nos invitan a reflexionar sobre intervención humana en el planeta."
        },
        "id_libros": [
            "MM2118",
            "MM1863",
            "MM1554"
        ]
    },
    "mayo": {
        "tema": {
            "en": "The Universe",
            "es": "El Universo"
        },
        "pregunta": {
            "en": "Throughout history, humanity has wondered about its place in the cosmos. With these books, you will explore the universe and what has been discovered so far.",
            "es": "A lo largo de la historia, la humanidad se ha preguntado sobre su lugar en el cosmos. Con estos libros, explorarás el universo y lo que hasta ahora se ha descubierto sobre él."
        },
        "id_libros": [
            "MM2624",
            "MM2712",
            "MM0903"
        ]
    },
    "junio": {
        "tema": {
            "en": "My body",
            "es": "Mi cuerpo"
        },
        "pregunta": {
            "en": "What we know about the human body has changed thanks to scientific discoveries and cultural changes. These books invite us to know the secrets of our body and its systems.",
            "es": "Nuestro conocimiento del cuerpo humano ha cambiado gracias a descubrimientos científicos y los cambios culturales. Estos libros nos invitan a conocer los secretos de nuestro cuerpo y sus sistemas."
        },
        "id_libros": [
            "MM2708",
            "MM2788",
            "MM1404"
        ]
    },
    "julio": {
        "tema": {
            "en": "Mystery and adventure",
            "es": "Misterio y aventura"
        },
        "pregunta": {
            "en": "Immerse yourself in stories full of enigmas to solve, intriguing characters and challenges to overcome. An invitation to unravel the unknown.",
            "es": "Sumérgente en historias cargadas de enigmas por resolver, personajes intrigantes y desafíos por superar. Una invitación a desentrañar lo desconocido."
        },
        "id_libros": [
            "MM2643",
            "MM2350",
            "MM1927"
        ]
    },
    "agosto": {
        "tema": {
            "en": "School",
            "es": "El colegio"
        },
        "pregunta": {
            "en": "Education defines our identity and the way we interpret the world around us. These stories show us many ways to educate ourselves.",
            "es": "La educación define nuestra identidad y la manera como interpretamos el mundo que nos rodea. Estas historias nos muestran muchas formas de educarnos."
        },
        "id_libros": [
            "MM2377",
            "MM1060",
            "MM2478"
        ]
    },
    "septiembre": {
        "tema": {
            "en": "My Family",
            "es": "Mi Familia"
        },
        "pregunta": {
            "en": "The family is the basic social unit that defines our identity. These books invite us to learn about our family history but also to carve our own path.",
            "es": "La familia es el núcleo social básico que define nuestra identidad. Estos libros nos invitan a conocer nuestra historia familiar pero también a labrar nuestro propio camino. "
        },
        "id_libros": [
            "MM2134",
            "MM2729",
            "MM2325"
        ]
    },
    "octubre": {
        "tema": {
            "en": "History",
            "es": "La Historia"
        },
        "pregunta": {
            "en": "The study of history allows us to understand our past and the events that have influenced our present day. This month we invite you to think about how the past defines the present and the future.",
            "es": "El estudio de la historia nos permite comprender nuestro pasado y los eventos que han influido en nuestra actualidad. Este mes te invitamos a pensar en cómo el pasado define el presente y el futuro. "
        },
        "id_libros": [
            "MM2402",
            "MM2527",
            "MM0257"
        ]
    },
    "noviembre": {
        "tema": {
            "en": "Myths and legends",
            "es": "Mitos y leyendas"
        },
        "pregunta": {
            "en": "Since ancient times there have been stories to answer the deepest enigmas of life but also to explain natural phenomena and to transmit teachings. We invite you to explore some myths of the Americas.",
            "es": "Desde la antiguedad existen relatos para dar respuesta a los enigmas más profundos de la vida pero también para explicar fenómenos naturales y para transmitir enseñanzas. Te invitamos a recorrer algunos mitos de las Américas. "
        },
        "id_libros": [
            "MM0040",
            "MM2726",
            "MM1280"
        ]
    },
    "diciembre": {
        "tema": {
            "en": "Poetry and song",
            "es": "Poesía y canción"
        },
        "pregunta": {
            "en": "Memories, emotions and lessons are stored in poems and songs. Reciting and singing is a game, is a pleasure but is also a way of learning.",
            "es": "En los poemas y las canciones se guardan memorias, emociones y enseñanzas. Recitar y cantar es una forma de jugar, es un placer y también una forma de aprender. "
        },
        "id_libros": [
            "MM2734",
            "MM2397",
            "MM1064"
        ]
    },
    "enero_2026": {
        "tema": {
            "en": "Childhood",
            "es": "La infancia"
        },
        "pregunta": {
            "en": "Stories that capture the curiosity, imagination and wonder of the first experiences of our lives and how these help us shape our worldview.",
            "es": "Relatos que capturan la curiosidad, la imaginación y el asombro que tienen las primeras experiencias de nuestra vida y cómo estas nos ayudan a moldear nuestra visión de mundo. "
        },
        "id_libros": [
            "MM2746",
            "MM2740",
            "MM2586"
        ]
    }
}

export const PAST_READING_PLANS = [
	{
		id: "2023",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2023_act.pdf",
			en: "/document/plan_lecturas_2023_act_en.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2023.jpg",
	},
	{
		id: "2022",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2022_act.pdf",
			en: "/document/plan_lecturas_2022_act_en.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2022.jpg",
	},
	{
		id: "2021",
		name: { default: "", translationPath: "" },
		file: {
			es: "/document/plan_lecturas_2021_act.pdf",
			en: "/document/plan_lecturas_2021_act.pdf",
		},
		img: "/img/club-makemake/plan-lectura/2021.jpg",
	},
];

export const READING_PLAN_MONTHS = [
	{
		id: "enero",
		date:"2025-01-01",
		name: {
			default: "Enero",
			translationPath: "meses.enero",
		},
		visible: true,
	},
	{
		id: "febrero",
		date:"2025-02-01",
		name: {
			default: "Febrero",
			translationPath: "meses.febrero",
		},
		visible: true,
	},
	{
		id: "marzo",
		date:"2025-03-01",
		name: {
			default: "Marzo",
			translationPath: "meses.marzo",
		},
		visible: true,
	},
	{
		id: "abril",
		date:"2025-04-01",
		name: {
			default: "Abril",
			translationPath: "meses.abril",
		},
		visible: true,
	},
	{
		id: "mayo",
		date:"2025-05-01",
		name: {
			default: "Mayo",
			translationPath: "meses.mayo",
		},
		visible: true,
	},
	{
		id: "junio",
		date:"2025-06-01",
		name: {
			default: "Junio",
			translationPath: "meses.junio",
		},
		visible: true,
	},
	{
		id: "julio",
		date:"2025-07-01",
		name: {
			default: "Julio",
			translationPath: "meses.julio",
		},
		visible: true,
	},
	{
		id: "agosto",
		date:"2025-08-01",
		name: {
			default: "Agosto",
			translationPath: "meses.agosto",
		},
		visible: true,
	},
	{
		id: "septiembre",
		date:"2025-09-01",
		name: {
			default: "Septiembre",
			translationPath: "meses.septiembre",
		},
		visible: true,
	},
	{
		id: "octubre",
		date:"2025-010-01",
		name: {
			default: "Octubre",
			translationPath: "meses.octubre",
		},
		visible: true,
	},
	{
		id: "noviembre",
		date:"2025-011-01",
		name: {
			default: "Noviembre",
			translationPath: "meses.noviembre",
		},
		visible: true,
	},
	{
		id: "diciembre",
		date:"2025-012-01",
		name: {
			default: "Diciembre",
			translationPath: "meses.diciembre",
		},
		visible: true,
	},
	{
		id: "enero_2026",
		date:"2026-01-01",
		name: {
			default: "Enero 2026",
			translationPath: "meses.enero"
		},
		year:2026,
		visible: true,
	},
];
