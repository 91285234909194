import axios from "axios";
import { GENERAL_URL_API } from "../../components/Store";

/**
 * Este servicio devuelve la información del formulario de eventos.
 * @param {{abortSignal:AbortSignal, idForm:String}}-abortSignal Y idForm - indica el identificador del formulario
 * @returns {Promise<{}>} retorna un objeto con la información correspondiente.
 */
const getEventInformation = async ({ abortSignal = null, idForm }) => {
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('idFormulario', idForm ?  idForm : '');

        const response = await axios({
            method: "POST",
            url: `assets/obtenerFormEvento`,
            signal: abortSignal || null,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: formData
        });
        const datos = response.data;
        if (datos === 'Error') {
            return new Error(datos);
        }
        return datos || {};
    } catch (error) {
        return Promise.reject(error);
    }
}

/**  Este servicio envia los datos de inscripción a un evento especifico
* @param {{abortSignal:AbortSignal, dataForm: Object}} 
* @returns {Promise<Object|Error>} Promise que resuelve en un objeto de datos o rechaza con un error.
*/
const sendRegistration = async ({ abortSignal = null, dataForm }) => {
    try {
        axios.defaults.baseURL = GENERAL_URL_API;
        axios.defaults.withCredentials = true;

        const formData = new FormData();
        formData.append('idFormulario', dataForm?.idFormulario ||'');
        formData.append('nombreCompleto', dataForm?.nombreCompleto ||'');
        formData.append('correo', dataForm?.correo ||'');
        formData.append('pais', dataForm?.pais ||'');
        formData.append('ciudad', dataForm?.ciudad ||'');
        formData.append('grupo', dataForm?.grupo ||'N/A');
        formData.append('perteneceInstitucion', dataForm?.perteneceInstitucion || 0);
        formData.append('nombreInstitucion', dataForm?.nombreInstitucion ||'');
        formData.append('accesoMakeMake', dataForm?.accesoMakeMake || 0);
        formData.append('mayorEdad', dataForm?.edad || 0);

        const response = await axios({
            method: "POST",
            url: `assets/enviarRegistroFormEvento`,
            signal: abortSignal || null,
            /* headers: { "Content-Type": "application/x-www-form-urlencoded" }, */
            headers: {"Content-Type":"multipart/form-data"},
            data: formData
        });
        const datos = response.data;
        if(datos === 'Error'){
        return new Error(datos);
        }
        return datos || {};
    } catch (error) {
        return Promise.reject(error);
    }
}

export const EventosFormularioAPI = {
    getEventInformation,
    sendRegistration
};
